import React, { Component } from 'react';

import api from '../../services/api';


class RegexPatterns extends Component {

    constructor(props) {
        super(props);

        this.state = {
            onlineLearningPattern: '',
            writtenAttendancePattern: '',
            saving: false
        };
    }

    handleOnlinePatternChange(e) {
        this.setState({ onlineLearningPattern: e.target.value });
    }

    handleWrittenPatternChange(e) {
        this.setState({ writtenAttendancePattern: e.target.value });
    }

    savePatterns() {
        this.setState({ saving: true });
        var dto = {
            onlineLearning: this.state.onlineLearningPattern,
            writtenAttendance: this.state.writtenAttendancePattern
        }
        api.updateRegexPatterns(dto).then(response => {
            this.setState({ saving: false });
        }).catch(error => {
            this.setState({ saving: false });
            console.log(error);
        });
    }

    fetchPatterns() {
        api.getRegexPatterns()
            .then(response => {
                this.setState({ onlineLearningPattern: response.data.onlineLearning, writtenAttendancePattern: response.data.writtenAttendance });
            })
            .catch(error => {
                console.log(error);
            });
    }

    componentDidMount() {
        this.fetchPatterns();
    };

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <div className="row mb-4">
                        <div className="col-2">
                            <button className={"btn " + (this.state.saving ? "btn-outline-secondary disabled" : "btn-outline-success")} type="button" disabled={this.state.saving} onClick={this.savePatterns.bind(this)} >
                                <div className="float-left">
                                    {this.state.saving && <div className="save-spinner"></div>}
                                    {!this.state.saving && <i className="fas fa-check"></i>}
                                </div>
                                <div className="button-text">Save</div>
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="onlineLearningPattern">Online Course pattern</label>
                                <input type="text" className="form-control" id="onlineLearningPattern" value={this.state.onlineLearningPattern} onChange={this.handleOnlinePatternChange.bind(this)} ></input>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="writtenAttendancePattern">Written attendence pattern</label>
                                <input type="text" className="form-control" id="writtenAttendancePattern" value={this.state.writtenAttendancePattern} onChange={this.handleWrittenPatternChange.bind(this)} ></input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RegexPatterns;
