import React from 'react';

function SubmissionInfo({ submissions }) {
    const stateCount = submissions.reduce((result, x) => {
        result[x.state]++;
        return result;
    }, [0, 0, 0, 0]);
    return (
        <>
            <span className="text-success">{stateCount[3]}</span>&nbsp;
            <span className="text-danger">{stateCount[2]}</span>&nbsp;
            <span className="text-warning">{stateCount[1]}</span>&nbsp;
            <span className="text-secondary">{stateCount[0]}</span>
        </>
    )
}

function SubmissionOverview({ submissions }) {
    return (
        <table style={{ width: '100%', tableLayout: 'fixed' }}>
            <thead>
                <tr style={{ fontWeight: 'bold' }}>
                    <td>Modul</td>
                    <td style={{ width: '140px' }}>Status</td>
                    <td style={{ width: '140px' }}>Studieaktivitet</td>
                    <td style={{ width: '48px' }}></td>
                </tr>
            </thead>
            <tbody>
                {submissions.map((x, index) =>
                    <tr key={index}>
                        <td>{x.name}</td>
                        <td>
                            {x.state === 3 && <span className="text-success">Godkendt</span>}
                            {x.state === 2 && <span className="text-danger">Ikke godkendt</span>}
                            {x.state === 1 && <span className="text-warning">Ikke godkendt</span>}
                            {x.state === 0 && <span className="text-secondary">-</span>}
                        </td>
                        <td>{x.firstTurnedInOrGradedDateFormated === "" ? "-" : x.firstTurnedInOrGradedDateFormated}</td>
                        <td><a href={x.url} target="_blank" rel="noopener noreferrer"><i className="fa fa-external-link-alt m-1"></i></a></td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}

export { SubmissionInfo, SubmissionOverview };
