import React, { useEffect } from 'react';
import { Switch, Route, Link, NavLink, useHistory } from 'react-router-dom';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import { Account, useAccount } from './components/Account';
import Home from './components/Home';
import Administration from './components/Administration';
import WrittenAttendance from './components/WrittenAttendance';
import OnlineLearning from './components/OnlineLearning';
import ManagementAndPlanning from './components/ManagementAndPlanning';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "react-datepicker/dist/react-datepicker.css";

function App() {
    const history = useHistory();
    const { account } = useAccount();

    useEffect(() => {
        if (account === null) {
            history.push("/");
        }
    }, [account, history]);


    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div className='bg-light' style={{ height: '64px', zIndex: 1000, boxShadow: '0px 4px 8px 0px #00000033' }} >
                <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                    <Navbar.Brand as={Link} to="/">Edulife Classroom Alerts</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            {account !== null && !account.noAccess &&
                                <>
                                    {account.isAdmin &&
                                        <NavItem>
                                            <Nav.Link as={NavLink} activeClassName="active" to="/administration">Administration</Nav.Link>
                                        </NavItem>
                                    }
                                    <NavItem>
                                        <Nav.Link as={NavLink} activeClassName="active" to="/gennemfoerelse">Gennemførelse</Nav.Link>
                                    </NavItem>
                                    <NavItem>
                                        <Nav.Link as={NavLink} activeClassName="active" to="/elearning">E-Learning</Nav.Link>
                                    </NavItem>
                                    <NavItem>
                                        <Nav.Link as={NavLink} activeClassName="active" to="/styring-og-planlaegning">Styring og planlægning</Nav.Link>
                                    </NavItem>
                                </>
                            }
                        </Nav>
                        <Nav>
                            <Account />
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
            <div style={{ padding: '16px', flex: 1 }}>
                <Switch>
                    <Route exact path="/"><Home /></Route>
                    {account !== null && !account.noAccess &&
                        <>
                            <Route exact path="/administration"><Administration /></Route>
                            <Route exact path="/gennemfoerelse"><WrittenAttendance /></Route>
                            <Route exact path="/elearning"><OnlineLearning /></Route>
                            <Route exact path="/styring-og-planlaegning"><ManagementAndPlanning /></Route>
                        </>
                    }
                </Switch>
            </div>
        </div>
    );
}

export default App;
