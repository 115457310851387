import React, { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';
import ActionButton from './ActionButton';
import SortableHeader from './SortableHeader';
import { SubmissionInfo, SubmissionOverview } from './Submission';
import { format } from 'date-fns';


function StudentView({ courseIds, studentIds, onCourseIdsChange, onCourseFilterChange, canComment, view, reload }) {

    const [loading, setLoading] = useState(false);
    const [student, setStudent] = useState(null);
    const [selectedCourseId, setSelectedCourseId] = useState(null);
    const [filteredCourses, setFilteredCourses] = useState([]);

    const [comment, setComment] = useState('');
    const [updateCommentInProgress, setUpdateCommentInProgress] = useState(false);

    const [sortProp, setSortProp] = useState('');
    const [sortDesc, setSortDesc] = useState(false);

    const loadStudent = useCallback(() => {
        setLoading(true);
        api.getOnlineLearningStudentDetails(studentIds[0]).then(response => {
            for (let i = 0; i < response.data.courses.length; i++) {
                const course = response.data.courses[i];

                course.failedSubmissions = course.submissions.reduce((result, x) => {
                    if (x.state === 1 || x.state === 2) {
                        result++;
                    }
                    return result;
                }, 0);

                if (course.nextActionDate !== null) {
                    course.nextActionDate = new Date(course.nextActionDate);
                    course.nextActionDateFormated = format(course.nextActionDate, 'dd/MM - yyyy');
                } else {
                    course.nextActionDate = new Date(null);
                    course.nextActionDateFormated = '';
                }
                if (course.warnDate !== null) {
                    course.warnDate = new Date(course.warnDate);
                    course.warnDateFormated = format(course.warnDate, 'dd/MM - yyyy');
                } else {
                    course.warnDate = new Date(null);
                    course.warnDateFormated = '';
                }
                if (course.expelDate !== null) {
                    course.expelDate = new Date(course.expelDate);
                    course.expelDateFormated = format(course.expelDate, 'dd/MM - yyyy');
                } else {
                    course.expelDate = new Date(null);
                    course.expelDateFormated = '';
                }

                for (let j = 0; j < course.submissions.length; j++) {
                    const submission = course.submissions[j];
                    if (submission.firstTurnedInOrGradedDate !== null) {
                        submission.firstTurnedInOrGradedDate = new Date(submission.firstTurnedInOrGradedDate);
                        submission.firstTurnedInOrGradedDateFormated = format(submission.firstTurnedInOrGradedDate, 'dd/MM - yyyy');
                    } else {
                        submission.firstTurnedInOrGradedDate = new Date(null);
                        submission.firstTurnedInOrGradedDateFormated = '';
                    }
                }
            }

            setStudent(response.data);
            setComment(response.data.comment);
            setLoading(false);
        }, e => {
            setLoading(false);
        });
    }, [studentIds]);

    useEffect(() => {
        if (studentIds.length === 1) {
            loadStudent();
        } else {
            setStudent(null);
        }
    }, [studentIds, loadStudent, reload]);

    useEffect(() => {
        if (student === null) {
            setFilteredCourses([]);
            return;
        }

        const filtered = student.courses.filter(x => {
            if (view === 'all') {
                return x.courseStudentState !== 'expelled';
            }
            if (view === 'behind') {
                return x.courseStudentState === 'behind';
            }
            if (view === 'warned') {
                return x.courseStudentState === 'warned';
            }
            if (view === 'expelled') {
                return x.courseStudentState === 'expelled';
            }
            return false
        });

        if (filtered.length > 0) {
            let type = typeof (filtered[0][sortProp]);
            if ((type === "number" || type === "object") && sortDesc) {
                filtered.sort((b, a) => a[sortProp] - b[sortProp]);
            }
            if ((type === "number" || type === "object") && !sortDesc) {
                filtered.sort((a, b) => a[sortProp] - b[sortProp]);
            }
            if (type === "boolean" && sortDesc) {
                filtered.sort((b, a) => (a[sortProp] === b[sortProp]) ? 0 : a[sortProp] ? -1 : 1);
            }
            if (type === "boolean" && !sortDesc) {
                filtered.sort((a, b) => (a[sortProp] === b[sortProp]) ? 0 : a[sortProp] ? -1 : 1);
            }
            if (type === "string" && sortDesc) {
                filtered.sort((b, a) => a[sortProp].localeCompare(b[sortProp]));
            }
            if (type === "string" && !sortDesc) {
                filtered.sort((a, b) => a[sortProp].localeCompare(b[sortProp]));
            }
        }

        setFilteredCourses(filtered);
    }, [view, student, sortProp, sortDesc]);

    const onCourseClick = (x) => {
        if (x.id === selectedCourseId) {
            setSelectedCourseId(null);
        } else {
            setSelectedCourseId(x.id);
        }
    }

    const updateComment = () => {
        setUpdateCommentInProgress(true);
        api.updateOnlineLearningStudentComment(student.id, comment).then(response => {
            setUpdateCommentInProgress(false);
            loadStudent();
        }, e => {
            setUpdateCommentInProgress(false);
        })
    }

    const onSort = (prop) => {
        if (prop === sortProp) {
            setSortDesc(x => !x);
        } else {
            setSortProp(prop);
            setSortDesc(false);
        }
    }

    const handleSelectCourse = (e, id) => {
        e.stopPropagation();
        if (courseIds.includes(id)) {
            onCourseIdsChange(courseIds.filter(x => x !== id));
        } else {
            onCourseIdsChange([...courseIds, id]);
        }
    }

    const handleSelectAll = () => {
        if (filteredCourses.every(x => courseIds.includes(x.id))) {
            onCourseIdsChange([]);
        } else {
            onCourseIdsChange(filteredCourses.map(x => x.id));
        }
    }

    const handleFilterCourse = (e, x) => {
        e.stopPropagation();
        onCourseFilterChange({ id: x.id, name: x.name })
    }

    const allSelected = filteredCourses.length !== 0 && filteredCourses.every(x => courseIds.includes(x.id));

    return (
        <>
            {loading === true &&
                <div style={{ display: 'flex' }}>
                    <div className="selected" style={{ width: '16px' }}></div>
                    <div style={{ marginLeft: '16px', padding: '16px' }}><i className="fa fa-spinner fa-pulse fa-2x"></i></div>
                    <div style={{ alignSelf: 'center' }}>Indlæser</div>
                </div>
            }
            {loading === false && student !== null &&
                <>
                    <div style={{ display: 'flex', marginBottom: '16px' }}>
                        <div className="selected" style={{ width: '16px' }}>
                        </div>
                        <div style={{ marginLeft: '16px' }}>
                            <div style={{ fontWeight: 'bold', marginBottom: '16px' }}>{student.firstname}&nbsp;{student.lastname}</div>
                            {canComment &&
                                <div>
                                    <ActionButton disabled={comment === student.comment} text={"Opdater bemærkning"} icon={<i className="fas fa-edit" />} onClick={() => updateComment()} inProgress={updateCommentInProgress} />
                                </div>
                            }
                        </div>
                        <div style={{ flex: 1, marginLeft: '16px' }}>
                            <textarea readOnly={!canComment} placeholder="bemærkning" className="form-control" rows="8" value={comment} onChange={(event) => { setComment(event.target.value) }} ></textarea>
                        </div>
                    </div>
                    <div>
                        <table className={'tab'}>
                            <thead>
                                <tr>
                                    <th style={{ width: '32px' }} onClick={() => handleSelectAll()}>
                                        <i className={"m-2 far " + (allSelected ? "fa-check-square" : "fa-square")}></i>
                                    </th>
                                    <th style={{ width: '16px' }}></th>
                                    <th style={{ width: '40%' }}><SortableHeader sortProp={sortProp} sortDesc={sortDesc} onClick={onSort} propName="name">Hold</SortableHeader></th>
                                    <th style={{ width: '20%' }}><SortableHeader sortProp={sortProp} sortDesc={sortDesc} onClick={onSort} propName="failedSubmissions">Modul status</SortableHeader></th>
                                    <th style={{ width: '20%' }}><SortableHeader sortProp={sortProp} sortDesc={sortDesc} onClick={onSort} propName="warnDate">Varselsdato</SortableHeader></th>
                                    <th style={{ width: '20%' }}>
                                        {view !== 'expelled' && <SortableHeader sortProp={sortProp} sortDesc={sortDesc} onClick={onSort} propName="nextActionDate">Næste varselsdato</SortableHeader>}
                                        {view === 'expelled' && <SortableHeader sortProp={sortProp} sortDesc={sortDesc} onClick={onSort} propName="expelDate">Udmeldtdato</SortableHeader>}
                                    </th>
                                    <th style={{ width: '32px' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredCourses.map(x =>
                                    <React.Fragment key={x.id}>
                                        <tr onClick={() => onCourseClick(x)} className={"can-click" + (x.id === selectedCourseId ? " selected-header" : "")}>
                                            <td onClick={(e) => handleSelectCourse(e, x.id)}>
                                                <i className={"m-2 far " + (courseIds.includes(x.id) ? "fa-check-square" : "fa-square faded")}></i>
                                            </td>
                                            <td className={(x.id === selectedCourseId ? "selected" : "")}></td>
                                            <td className="table-name ellipsis">{x.name}</td>
                                            <td className="ellipsis"><SubmissionInfo submissions={x.submissions} /></td>
                                            <td className="ellipsis">{x.warnDateFormated}</td>
                                            <td className="ellipsis">
                                                {view !== 'expelled' && x.nextActionDateFormated}
                                                {view === 'expelled' && x.expelDateFormated}
                                            </td>
                                            <td onClick={(e) => handleFilterCourse(e, x)}>
                                                <i className="fas fa-user faded" title="Se andre elev" />
                                            </td>
                                        </tr>
                                        {x.id === selectedCourseId &&
                                            <tr style={{ border: 'none', boxShadow: '0 8px 8px 2px rgb(0 0 0 / 20%)' }}>
                                                <td className={"selected"} />
                                                <td colSpan={7}>
                                                    <div >
                                                        <div style={{ display: 'flex', margin: '16px', minHeight: '300px' }}>
                                                            <div style={{ flex: 1, marginRight: '16px', maxWidth: '1000px' }}>
                                                                <SubmissionOverview submissions={x.submissions} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </React.Fragment>
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
            }
        </>
    )
}

export default StudentView;
