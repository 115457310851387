import React from 'react';
import { useAccount } from './Account';


function Home() {

    const { account } = useAccount();

    return (
        <>
            {account === null &&
                <div className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
                    <div className="shadow-sm p-5 mb-5 bg-light rounded text-center w-25">
                        <div>
                            <h1 className="h4 mb-3 font-weight-normal">Please sign in</h1>
                        </div>
                    </div>
                </div>
            }
            {account !== null && account.noAccess &&
                <div className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
                    <div className="shadow-sm p-5 mb-5 bg-light rounded text-center w-25">
                        <div>
                            <h1 className="h6 mb-3 font-weight-normal">You do not have permission to access this page</h1>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Home;
