import React, { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';
import ActionButton from './ActionButton';
import { SubmissionInfo, SubmissionOverview } from './Submission';
import { format } from 'date-fns';
import SortableHeader from './SortableHeader';


function CourseView({ courseIds, studentIds, onStudentIdsChange, onStudentFilterChange, canComment, view, reload }) {

    const [loading, setLoading] = useState(false);
    const [course, setCourse] = useState(null);
    const [focusedStudentId, setFocusedtudentId] = useState(null);
    const [filteredStudents, setFilteredStudents] = useState([]);

    const [comment, setComment] = useState('');
    const [updateCommentInProgress, setUpdateCommentInProgress] = useState(false);

    const [sortProp, setSortProp] = useState('');
    const [sortDesc, setSortDesc] = useState(false);

    const loadCourse = useCallback(() => {
        setLoading(true);
        api.getOnlineLearningCourseDetails(courseIds[0]).then(response => {
            let c = response.data;
            for (let i = 0; i < c.students.length; i++) {
                const student = c.students[i];

                student.failedSubmissions = student.submissions.reduce((result, x) => {
                    if (x.state === 1 || x.state === 2) {
                        result++;
                    }
                    return result;
                }, 0);

                if (student.nextActionDate !== null) {
                    student.nextActionDate = new Date(student.nextActionDate);
                    student.nextActionDateFormated = format(student.nextActionDate, 'dd/MM - yyyy');
                } else {
                    student.nextActionDate = new Date(null);
                    student.nextActionDateFormated = '';
                }
                if (student.warnDate !== null) {
                    student.warnDate = new Date(student.warnDate);
                    student.warnDateFormated = format(student.warnDate, 'dd/MM - yyyy');
                } else {
                    student.warnDate = new Date(null);
                    student.warnDateFormated = '';
                }
                if (student.expelDate !== null) {
                    student.expelDate = new Date(student.expelDate);
                    student.expelDateFormated = format(student.expelDate, 'dd/MM - yyyy');
                } else {
                    student.expelDate = new Date(null);
                    student.expelDateFormated = '';
                }

                for (let j = 0; j < student.submissions.length; j++) {
                    const submission = student.submissions[j];
                    if (submission.firstTurnedInOrGradedDate !== null) {
                        submission.firstTurnedInOrGradedDate = new Date(submission.firstTurnedInOrGradedDate);
                        submission.firstTurnedInOrGradedDateFormated = format(submission.firstTurnedInOrGradedDate, 'dd/MM - yyyy');
                    } else {
                        submission.firstTurnedInOrGradedDate = new Date(null);
                        submission.firstTurnedInOrGradedDateFormated = '';
                    }
                }
            }

            setCourse(c);
            setLoading(false);
        }, e => {
            setLoading(false);
        });
    }, [courseIds]);

    useEffect(() => {
        if (courseIds.length === 1) {
            loadCourse();
        } else {
            setCourse(null);
        }
    }, [courseIds, loadCourse, reload]);

    useEffect(() => {
        if (course === null) {
            setFilteredStudents([]);
            return;
        }

        const filtered = course.students.filter(x => {
            if (view === 'all') {
                return x.courseStudentState !== 'expelled';
            }
            if (view === 'behind') {
                return x.courseStudentState === 'behind';
            }
            if (view === 'warned') {
                return x.courseStudentState === 'warned';
            }
            if (view === 'expelled') {
                return x.courseStudentState === 'expelled';
            }
            return false
        });

        if (filtered.length > 0) {
            let type = typeof (filtered[0][sortProp]);
            if ((type === "number" || type === "object") && sortDesc) {
                filtered.sort((b, a) => a[sortProp] - b[sortProp]);
            }
            if ((type === "number" || type === "object") && !sortDesc) {
                filtered.sort((a, b) => a[sortProp] - b[sortProp]);
            }
            if (type === "boolean" && sortDesc) {
                filtered.sort((b, a) => (a[sortProp] === b[sortProp]) ? 0 : a[sortProp] ? -1 : 1);
            }
            if (type === "boolean" && !sortDesc) {
                filtered.sort((a, b) => (a[sortProp] === b[sortProp]) ? 0 : a[sortProp] ? -1 : 1);
            }
            if (type === "string" && sortDesc) {
                filtered.sort((b, a) => a[sortProp].localeCompare(b[sortProp]));
            }
            if (type === "string" && !sortDesc) {
                filtered.sort((a, b) => a[sortProp].localeCompare(b[sortProp]));
            }
        }

        setFilteredStudents(filtered);
    }, [view, course, sortProp, sortDesc]);

    useEffect(() => {
        onStudentIdsChange([]);
    }, [view, course, onStudentIdsChange]);

    const onStudentClick = (x) => {
        if (x.id === focusedStudentId) {
            setFocusedtudentId(null);
            setComment('');
        } else {
            setFocusedtudentId(x.id);
            setComment(x.comment);
        }
    }

    const updateComment = (studentId, text) => {
        setUpdateCommentInProgress(true);
        api.updateOnlineLearningStudentComment(studentId, text).then(response => {
            setUpdateCommentInProgress(false);
            loadCourse();
        }, e => {
            setUpdateCommentInProgress(false);
        })
    }

    const onSort = (prop) => {
        if (prop === sortProp) {
            setSortDesc(x => !x);
        } else {
            setSortProp(prop);
            setSortDesc(false);
        }
    }

    const handleSelectStudent = (e, id) => {
        e.stopPropagation();
        if (studentIds.includes(id)) {
            onStudentIdsChange(studentIds.filter(x => x !== id));
        } else {
            onStudentIdsChange([...studentIds, id]);
        }
    }

    const handleSelectAll = () => {
        if (filteredStudents.every(x => studentIds.includes(x.id))) {
            onStudentIdsChange([]);
        } else {
            onStudentIdsChange(filteredStudents.map(x => x.id));
        }
    }

    const handleFilterStudent = (e, x) => {
        e.stopPropagation();
        onStudentFilterChange({ id: x.id, firstname: x.firstname, lastname: x.lastname });
    }

    const allSelected = filteredStudents.length !== 0 && filteredStudents.every(x => studentIds.includes(x.id));

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {loading === true &&
                <div style={{ display: 'flex' }}>
                    <div className="selected" style={{ width: '16px' }}></div>
                    <div style={{ marginLeft: '16px', padding: '16px' }}><i className="fa fa-spinner fa-pulse fa-2x"></i></div>
                    <div style={{ alignSelf: 'center' }}>Indlæser</div>
                </div>
            }
            {loading === false && course !== null &&
                <>
                    <div style={{ display: 'flex', marginBottom: '16px' }}>
                        <div className="selected" style={{ width: '16px' }}>
                        </div>
                        <div style={{ minWidth: '300px', margin: '16px' }}>
                            <span style={{ fontWeight: 'bold', marginRight: '16px' }}>{course.name}</span>
                            <a href={course.url} target="_blank" rel="noopener noreferrer"><i className="fa fa-external-link-alt m-1"></i></a>
                        </div>
                    </div>
                    <div style={{ flex: 1, position: 'relative', overflowY: 'scroll' }}>
                        <div style={{ position: 'absolute' }}>
                            <table className={'tab'}>
                                <thead>
                                    <tr>
                                        <th style={{ width: '32px' }} onClick={() => handleSelectAll()}>
                                            <i className={"m-2 far " + (allSelected ? "fa-check-square" : "fa-square")}></i>
                                        </th>
                                        <th style={{ width: '16px' }}></th>
                                        <th style={{ width: '15%' }}>
                                            <SortableHeader sortProp={sortProp} sortDesc={sortDesc} onClick={onSort} propName="firstname">Fornavn</SortableHeader>
                                        </th>
                                        <th style={{ width: '15%' }}>
                                            <SortableHeader sortProp={sortProp} sortDesc={sortDesc} onClick={onSort} propName="lastname">Efternavn</SortableHeader>
                                        </th>
                                        <th style={{ width: '10%' }}>
                                            <SortableHeader sortProp={sortProp} sortDesc={sortDesc} onClick={onSort} propName="failedSubmissions">Modul status</SortableHeader>
                                        </th>
                                        <th style={{ width: '15%' }}><SortableHeader sortProp={sortProp} sortDesc={sortDesc} onClick={onSort} propName="warnDate">Varselsdato</SortableHeader></th>
                                        <th style={{ width: '15%' }}>
                                            {view !== 'expelled' && <SortableHeader sortProp={sortProp} sortDesc={sortDesc} onClick={onSort} propName="nextActionDate">Næste varselsdato</SortableHeader>}
                                            {view === 'expelled' && <SortableHeader sortProp={sortProp} sortDesc={sortDesc} onClick={onSort} propName="expelDate">Udmeldtdato</SortableHeader>}
                                        </th>
                                        <th style={{ width: '30%' }}>Bemærkning</th>
                                        <th style={{ width: '32px' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredStudents.map(x =>
                                        <React.Fragment key={x.id}>
                                            <tr onClick={() => onStudentClick(x)} className={"can-click" + (x.id === focusedStudentId ? " selected-header" : "")}>
                                                <td onClick={(e) => handleSelectStudent(e, x.id)}>
                                                    <i className={"m-2 far " + (studentIds.includes(x.id) ? "fa-check-square" : "fa-square faded")}></i>
                                                </td>
                                                <td className={(x.id === focusedStudentId ? "selected" : "")}></td>
                                                <td className="table-name ellipsis">{x.firstname}</td>
                                                <td className="table-name ellipsis">{x.lastname}</td>
                                                <td className="ellipsis">
                                                    <SubmissionInfo submissions={x.submissions}></SubmissionInfo>
                                                </td>
                                                <td className="ellipsis">{x.warnDateFormated}</td>
                                                <td className="ellipsis">
                                                    {view !== 'expelled' && x.nextActionDateFormated}
                                                    {view === 'expelled' && x.expelDateFormated}
                                                </td>
                                                <td className="ellipsis">{x.comment}</td>
                                                <td onClick={(e) => handleFilterStudent(e, x)}>
                                                    <i className="fas fa-graduation-cap faded" title="Se hold" />
                                                </td>
                                            </tr>
                                            {x.id === focusedStudentId &&
                                                <tr style={{ border: 'none', boxShadow: '0 8px 8px 2px rgb(0 0 0 / 20%)' }}>
                                                    <td></td>
                                                    <td className={"selected"} />
                                                    <td colSpan={7}>
                                                        <div >
                                                            <div style={{ display: 'flex', margin: '16px' }}>
                                                                <div style={{ flex: 1, marginRight: '16px' }}>
                                                                    <SubmissionOverview submissions={x.submissions} />
                                                                </div>
                                                                <div style={{ flex: 1 }}>
                                                                    <textarea readOnly={!canComment} placeholder="bemærkning" className="form-control" rows="8" value={comment} onChange={(event) => { setComment(event.target.value) }} ></textarea>
                                                                    {canComment &&
                                                                        <div style={{ display: 'flex', marginTop: '16px' }}>
                                                                            <div style={{ flex: 1 }} />
                                                                            <ActionButton disabled={comment === x.comment} text={"Opdater bemærkning"} icon={<i className="fas fa-edit" />} onClick={() => updateComment(x.id, comment)} inProgress={updateCommentInProgress} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                        </React.Fragment>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default CourseView;
