import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const apiInstance = {
    credential: null,
    signInResult: null,
    signInResultPromise: null,
    onSignInError: null,

    get: (url, params) => apiInstance.getToken().then(x => axios.get(url, { headers: { Authorization: `Bearer ${x}` }, params })),
    post: (url, data) => apiInstance.getToken().then(x => axios.post(url, data, { headers: { Authorization: `Bearer ${x}` } })),

    getSignInResult: () => {
        if (apiInstance.credential === null) {
            return Promise.reject();
        }
        if (apiInstance.signInResultPromise !== null) {
            return apiInstance.signInResultPromise;
        }
        if (apiInstance.signInResult !== null && apiInstance.signInResult.tokenValidUntil > Date.now()) {
            return Promise.resolve(apiInstance.signInResult);
        }

        const body = new FormData();
        body.append('googleCredential', apiInstance.credential);

        apiInstance.signInResultPromise = axios.post('auth/signin', body).then(x => {
            apiInstance.signInResult = {
                account: x.data.account,
                token: x.data.token,
                tokenValidUntil: Date.now() + ((x.data.tokenExpiresIn - 60) * 1000)
            };
            apiInstance.signInResultPromise = null;

            return apiInstance.signInResult;
        });
        if (apiInstance.onSignInError !== null) {
            apiInstance.signInResultPromise.catch(apiInstance.onSignInError);
        }
        return apiInstance.signInResultPromise;
    },

    getToken: () => apiInstance.getSignInResult().then(x => x.token)
}

const api = {
    onCredentialChange: (credential, onSignInError) => {
        apiInstance.credential = credential;
        apiInstance.signInResult = null;
        apiInstance.signInResultPromise = null;
        apiInstance.onSignInError = onSignInError;

        return apiInstance.getSignInResult().then(x => x.account, () => null);
    },

    getWrittenAttendanceCourses: (n) => apiInstance.get('WrittenAttendance/Courses', { nameFilter: n, }),
    getWrittenAttendanceStudents: (n) => apiInstance.get('WrittenAttendance/Students', { nameFilter: n }),
    getWrittenAttendanceEntries: (c, s) => apiInstance.get('WrittenAttendance/Entries', { courseIdsFilter: c, studentIdFilter: s }),
    saveWrittenAttendanceStudentComment: (s, c) => apiInstance.post('WrittenAttendance/StudentComment', { studentId: s, comment: c }),
    writtenAttendanceWarn: (dto) => apiInstance.post('WrittenAttendance/Warn', dto),
    getSchoolStatistics: () => apiInstance.get('WrittenAttendance/SchoolStatistics'),

    getOnlineLearningCourses: (n) => apiInstance.get('OnlineLearning/Courses', { nameFilter: n }),
    getOnlineLearningStudents: (n) => apiInstance.get('OnlineLearning/Students', { nameFilter: n }),
    getOnlineLearningActionDates: () => apiInstance.get('OnlineLearning/ActionDates'),
    getOnlineLearningCourseDetails: (c) => apiInstance.get('OnlineLearning/CourseDetails', { courseId: c }),
    getOnlineLearningStudentDetails: (s) => apiInstance.get('OnlineLearning/StudentDetails', { studentId: s }),

    updateOnlineLearningStudentComment: (s, c) => apiInstance.post('OnlineLearning/StudentComment', { studentId: s, comment: c }),
    updateOnlineLearningNextActionDate: (x) => apiInstance.post('OnlineLearning/NextActionDate', x),
    onlineLearningRemindStudent: (x) => apiInstance.post('OnlineLearning/RemindStudent', x),
    onlineLearningWarnStudent: (x) => apiInstance.post('OnlineLearning/WarnStudent', x),
    onlineLearningExpelStudent: (x) => apiInstance.post('OnlineLearning/ExpelStudent', x),
    onlineLearningExpelStudentNoEmail: (x) => apiInstance.post('OnlineLearning/ExpelStudentNoEmail', x),
    onlineLearningUnexpelStudent: (x) => apiInstance.post('OnlineLearning/UnexpelStudent', x),
    onlineLearningSubmissionReport: (x) => apiInstance.get('OnlineLearning/SubmissionReport', x),
    onlineLearningWarnedReport: (x) => apiInstance.get('OnlineLearning/WarnedReport', x),
    onlineLearningExpelledReport: (x) => apiInstance.get('OnlineLearning/ExpelledReport', x),

    getOnlineLearningSubmissions: (w, c, s) => apiInstance.get('OnlineLearning/StudentSubmissions', { warnedFilter: w, courseIdFilter: c, studentIdFilter: s }),
    getOnlineLearningExpulsions: (c, s) => apiInstance.get('OnlineLearning/Expulsions', { courseIdFilter: c, studentIdFilter: s }),
    getOnlineLearningOverviewSubmissions: (c, s) => apiInstance.get('OnlineLearning/OverviewSubmissions', { courseId: c, studentId: s }),


    getNewAppUser: (q) => apiInstance.get('Admin/NewAppUser', { query: q }),
    getAppUsers: () => apiInstance.get('Admin/AppUsers'),
    updateAppUsers: (dto) => apiInstance.post('Admin/AppUsers', dto),

    getRegexPatterns: (dto) => apiInstance.get('Admin/RegexPatterns', dto),
    updateRegexPatterns: (dto) => apiInstance.post('Admin/RegexPatterns', dto),

    getEmailTemplates: () => apiInstance.get('Admin/EmailTemplates'),
    updateEmailTemplates: (dto) => apiInstance.post('Admin/EmailTemplates', dto),

    getArchiveEmails: () => apiInstance.get('Admin/ArchiveEmails'),
    updateArchiveEmails: (x) => apiInstance.post('Admin/ArchiveEmails', x)
};

export default api;
