import React, { Component } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import api from '../../services/api';

class CourseStudentFilter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            courseLoading: false,
            courses: [],
            courseSelected: [],
            studentLoading: false,
            students: [],
            studentSelected: [],
        }
    };

    getCourses(query) {
        this.setState({ courseLoading: true });

        api.getWrittenAttendanceCourses(query)
            .then(response => {
                this.setState({ courseLoading: false, courses: response.data });
            })
            .catch(error => {
                this.setState({ courseLoading: false });
                console.log(error);
            });
    }

    onCourseSelected(selection) {
        this.setState({ courseSelected: selection, studentSelected: [] });
        this.props.onFilterChange(selection.length === 1 ? selection[0].id : null, null);
    }

    getStudents(query) {
        this.setState({ studentLoading: true });

        api.getWrittenAttendanceStudents(query)
            .then(response => {
                this.setState({ studentLoading: false, students: response.data });
            })
            .catch(error => {
                this.setState({ studentLoading: false });
                console.log(error);
            });
    }

    onStudentSelected(selection) {
        this.setState({ studentSelected: selection, courseSelected: [] });
        this.props.onFilterChange(null, selection.length === 1 ? selection[0].id : null);
    }

    componentDidMount() {
    };

    render() {
        return (
            <div className="row">
                <div className="col-8">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Hold</span>
                        </div>
                        <AsyncTypeahead
                            id="course-typeahead"
                            isLoading={this.state.courseLoading}
                            labelKey="name"
                            onSearch={this.getCourses.bind(this)}
                            options={this.state.courses}
                            selected={this.props.selected.course}
                            onChange={(sel) => this.props.onSelectedChange({ course: sel, student: [] })}
                            delay={300}
                            minLength={3}
                            clearButton
                            multiple
                            promptText=""
                        />
                    </div>
                </div>
                <div className="col-4">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Elev</span>
                        </div>
                        <AsyncTypeahead
                            id="course-typeahead"
                            isLoading={this.state.studentLoading}
                            labelKey={(x) => x.firstname + " " + x.lastname}
                            onSearch={this.getStudents.bind(this)}
                            options={this.state.students}
                            selected={this.props.selected.student}
                            onChange={(sel) => this.props.onSelectedChange({ course: [], student: sel })}
                            delay={300}
                            minLength={3}
                            clearButton
                            promptText=""
                        />
                    </div>
                </div>
            </div>
        )
    }
};

export default CourseStudentFilter;