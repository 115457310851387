import React, { Component } from 'react';
import CourseStudentFilter from './CourseStudentFilter';
import api from '../../services/api';
import EditCommentModal from '../../modals/EditCommentModal';
import ItemTable from '../ItemTable';
import { AccountContext } from '../Account';

class WrittenAttendance extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filterSelected: { course: [], student: [] },
            filter: { courseIds: [], studentId: null },

            entries: [],
            selectedEntries: [],
            warnInProgress: false,

            commentEdit: {}
        }
    };

    onFilterSelectedChange(sel) {
        let filter = {
            courseIds: sel.course.length === 0 ? [] : sel.course.map(x => x.id),
            studentId: sel.student.length === 0 ? null : sel.student[0].id
        };

        this.setState({ filterSelected: sel, filter: filter });
        this.getEntries(filter);
    }

    getEntries(filter) {
        if (filter.courseIds.length === 0 && filter.studentId === null) {
            this.setState({ message: this.getNoFilterMessage() });
            return;
        }
        this.setState({ message: this.getLoadingMessage() });
        api.getWrittenAttendanceEntries(filter.courseIds, filter.studentId)
            .then(response => {
                this.setState({ message: response.data.length === 0 ? this.getNoDataMessage() : null, entries: response.data });
            })
            .catch(error => {
                this.setState({ message: this.getNoDataMessage() });
                console.log(error);
            });
    }

    warn() {
        this.setState({ warnInProgress: true });
        let studentIds = this.state.selectedEntries.map(x => x.id);

        api.writtenAttendanceWarn(studentIds).then(response => {
            this.getEntries(this.state.filter);
            this.setState({ warnInProgress: false });
        }).catch(error => {
            this.setState({ warnInProgress: false });
            console.log(error);
        });
    }

    showEditComment(item, event) {
        event.stopPropagation();

        let onUpdate = function (text) {
            api.saveWrittenAttendanceStudentComment(item.id, text).then(response => {

            }).catch(error => {
                console.log(error);
            })
            item.comment = text;
            this.setState({ students: [...this.state.students] });
        }

        this.setState({ commentEdit: { content: item.comment, onContentUpdate: onUpdate.bind(this) } });
    };

    getLoadingMessage() {
        return (
            <div>
                <i className="fa fa-spinner fa-pulse fa-2x"></i>
                <span className="m-3">Indlæser</span>
            </div>)
    }

    getNoDataMessage() {
        return (<div>
            <i className="fa fa-info-circle fa-2x"></i>
            <span className="m-3">Ingen data fundet</span>
        </div>)
    }

    getNoFilterMessage() {
        return (
            <div>
                <i className="fa fa-info-circle fa-2x"></i>
                <span className="m-3">Ingen filtre indstillet</span>
            </div>
        )
    }

    componentDidMount() {
        this.getEntries(this.state.filter);
    }

    formatFailedProcent(failedProcent) {
        return Number(failedProcent).toFixed(1) + '%';
    }

    formatEvolution(evolution) {
        var evo = evolution > 0 ? '+' : '';
        evo = evo + Number(evolution).toFixed(1) + '%';
        return evo;
    }

    render() {
        const account = this.context;
        const isAdmin = account.isAdmin || account.isWrittenAttendanceAdmin;

        return (
            <div className="card my-4">
                <div className="card-body">
                    <CourseStudentFilter selected={this.state.filterSelected} onSelectedChange={this.onFilterSelectedChange.bind(this)}></CourseStudentFilter>

                    <div style={{ height: 700, backgroundColor: '#e9ecef', overflowY: 'scroll' }}>
                    <ItemTable columns={[
                        {
                            text: "Fornavn", width: "15%", sortProp: 'firstname', cellRender: item => <span >{item.firstname}</span>
                        },
                        {
                            text: "Efternavn", width: "15%", sortProp: 'lastname', cellRender: item => <span>{item.lastname}</span>
                        },
                        {
                            text: "Fraværsprocent", width: "10%", sortProp: 'failedProcent', cellRender: item => <span>{this.formatFailedProcent(item.failedProcent)}</span>
                        },
                        {
                            text: "Udvikling", width: "10%", sortProp: 'evolution', cellRender: item => <span>{this.formatEvolution(item.evolution)}</span>
                        },
                        {
                            text: "Sidst varslet", width: "10%", sortProp: 'warningDateValue', cellRender: item =>
                                <span className="clickable" onClick={() => this.props.onFilterOnStudent(item)} >{item.warningDate}</span>
                        },
                        {
                            text: "Bemærkning", width: "35%", cellRender: item =>
                                <div>
                                    <span >{item.comment}</span>
                                    <span onClick={(event) => this.showEditComment(item, event)}>
                                        <i className="far fa-edit float-right"></i>
                                    </span>
                                </div>
                        },
                    ]} items={this.state.entries} onSelectItems={x => { this.setState({ selectedEntries: x }) }} message={this.state.message} >
                    </ItemTable>
                    </div>

                    <EditCommentModal editItem={this.state.commentEdit} />

                    {isAdmin &&
                        <div className="row mt-4">
                            <div className="col-12">
                                <button className={"btn float-left mr-4 " + (!this.state.warnInProgress && this.state.entries.length !== 0 ? "btn-outline-secondary" : "btn-outline-secondary disabled")} type="button" disabled={this.state.selectedEntries.length === 0} onClick={this.warn.bind(this)} >
                                    <div className="float-left">
                                        {this.state.warnInProgress && <div className="save-spinner"></div>}
                                        {!this.state.warnInProgress && <i className="fas fa-clock"></i>}
                                    </div>
                                    <div className="button-text">Tildel varsel</div>
                                </button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
};

WrittenAttendance.contextType = AccountContext;

export default WrittenAttendance;