import React, { Component } from 'react';
import RegexPatterns from './RegexPatterns';
import EmailTemplate from './EmailTemplate';
import UserRoles from './UserRoles';
import ArchiveEmails from './ArchiveEmails';

class Administration extends Component {

    constructor(props) {
        super(props);

        this.state = {
            views: [
                {
                    id: 1,
                    buttonText: 'Users and roles',
                    render: () => <UserRoles></UserRoles>
                },
                {
                    id: 2,
                    buttonText: 'RegEx patterns',
                    render: () => <RegexPatterns></RegexPatterns>
                },
                {
                    id: 3,
                    buttonText: 'Email Templates',
                    render: () => <EmailTemplate></EmailTemplate>
                },
                {
                    id: 4,
                    buttonText: 'Archive emails',
                    render: () => <ArchiveEmails></ArchiveEmails>
                }
            ],
        };
    }

    switchView(view) {
        this.setState({ activeView: view });
    }

    componentDidMount() {
        this.setState({ activeView: this.state.views[0] });
    };

    render() {

        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <ul className="nav nav-pills my-2" id="pills-tab">
                    {this.state.views.map(view =>
                        <li key={view.id} className="nav-item m-1" role="presentation">
                            <button className={"btn " + (view === this.state.activeView ? "btn-info" : "btn-outline-info")} onClick={() => this.switchView(view)} type="button">{view.buttonText}</button>
                        </li>
                    )}
                </ul>
                {this.state.activeView && this.state.activeView.render()}
            </div>
        )
    }
}

export default Administration;
