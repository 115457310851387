import React from 'react';

function ActionButton({ onClick, inProgress, text, icon, disabled }) {

    const buttonDisabled = disabled || inProgress;
    return (
        <button className={"btn btn-outline-secondary" + (buttonDisabled ? " disabled" : "")} disabled={buttonDisabled} type="button" onClick={onClick} >
            <div className="float-left">
                {inProgress === true && <div className="save-spinner"></div>}
                {inProgress !== true && icon}
            </div>
            <div className="button-text">{text}</div>
        </button>
    )
}

export default ActionButton;
