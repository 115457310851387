import React, { Component } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import ItemTable from '../ItemTable';
import api from '../../services/api';

const ref = React.createRef();

class UserRoles extends Component {

    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            usersLoading: false,
            newUsers: [],
            appUsers: []
        };
    }



    fetchUsers(query) {
        this.setState({ usersLoading: true });
        api.getNewAppUser(query)
            .then(response => {
                this.setState({ usersLoading: false, newUsers: response.data });
            })
            .catch(error => {
                this.setState({ usersLoading: false });
                console.log(error);
            });
    }

    handleUserSelected(ta, selected) {
        ta.current.clear();
        let item = selected[0];
        let ur = [...this.state.appUsers];
        var existing = ur.find(x => x.id === item.id);
        if (existing) {
            return;
        }
        ur.push(item);
        this.setState({ appUsers: ur });
    }

    removeAppUser(item) {
        let ur = [...this.state.appUsers];
        let index = ur.indexOf(item);
        if (index < 0) {
            return;
        }
        ur.splice(index, 1)
        this.setState({ appUsers: ur });
    }

    toggleAdminRole(item) {
        item.hasAdminRole = !item.hasAdminRole;
        this.setState({ appUsers: [...this.state.appUsers] });
    }

    toggleOnlineLearningAdminRole(item) {
        item.hasOnlineLearningAdminRole = !item.hasOnlineLearningAdminRole;
        this.setState({ appUsers: [...this.state.appUsers] });
    }

    toggleOnlineLearningTeacherRole(item) {
        item.hasOnlineLearningTeacherRole = !item.hasOnlineLearningTeacherRole;
        this.setState({ appUsers: [...this.state.appUsers] });
    }

    toggleWrittenAttendanceAdminRole(item) {
        item.hasWrittenAttendanceAdminRole = !item.hasWrittenAttendanceAdminRole;
        this.setState({ appUsers: [...this.state.appUsers] });
    }

    toggleManagementAndPlanningRole(item) {
        item.hasManagementAndPlanningRole = !item.hasManagementAndPlanningRole;
        this.setState({ appUsers: [...this.state.appUsers] });
    }

    toggleObserverRole(item) {
        item.hasObserverRole = !item.hasObserverRole;
        this.setState({ appUsers: [...this.state.appUsers] });
    }

    fetchAppUsers() {
        api.getAppUsers()
            .then(response => {
                this.setState({ appUsers: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    }

    saveAppUsers() {
        this.setState({ saving: true });
        api.updateAppUsers(this.state.appUsers)
            .then(response => {
                this.setState({ saving: false });
            }).catch(error => {
                this.setState({ saving: false });
                console.log(error);
            });
    }

    componentDidMount() {
        this.fetchAppUsers();
    };

    render() {
        return (
            <div className="card" style={{ flex: 1 }}>
                <div className="card-body" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="row mb-4">
                        <div className="col-3">
                            <button className={"btn " + (this.state.saving ? "btn-outline-secondary disabled" : "btn-outline-success")} type="button" disabled={this.state.saving} onClick={this.saveAppUsers.bind(this)} >
                                <div className="float-left">
                                    {this.state.saving && <div className="save-spinner"></div>}
                                    {!this.state.saving && <i className="fas fa-check"></i>}
                                </div>
                                <div className="button-text">Save</div>
                            </button>
                        </div>
                        <div className="col-9">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="subjectSideText">New user:</span>
                                </div>
                                <AsyncTypeahead
                                    id="course-typeahead"
                                    isLoading={this.state.usersLoading}
                                    labelKey={option => `${option.firstname} ${option.lastname}`}
                                    onSearch={(query) => this.fetchUsers(query)}
                                    options={this.state.newUsers}
                                    onChange={(selected) => this.handleUserSelected(ref, selected)}
                                    placeholder="type user name..."
                                    delay={300}
                                    minLength={3}
                                    ref={ref}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ flex: 1, position: 'relative', overflowY: 'scroll', backgroundColor: '#e9ecef' }}>
                        <div style={{ position: 'absolute' }}>
                            <ItemTable columns={[
                                {
                                    text: "Navn", width: "20%", sortProp: 'name', cellRender: item => <span title={item.email}>{item.name}</span>
                                },
                                {
                                    text: "App admin", width: "12%", sortProp: 'hasAdminRole', cellRender: item =>
                                        <span className="px-3" onClick={() => this.toggleAdminRole(item)}><i className={"far " + (item.hasAdminRole ? "fa-check-square" : "fa-square")}></i></span>
                                },
                                {
                                    text: "E-Learning admin", width: "12%", sortProp: 'hasOnlineLearningAdminRole', cellRender: item =>
                                        <span className="px-3" onClick={() => this.toggleOnlineLearningAdminRole(item)}><i className={"far " + (item.hasOnlineLearningAdminRole ? "fa-check-square" : "fa-square")}></i></span>
                                },
                                {
                                    text: "E-learning lærer", width: "12%", sortProp: 'hasOnlineLearningTeacherRole', cellRender: item =>
                                        <span className="px-3" onClick={() => this.toggleOnlineLearningTeacherRole(item)}><i className={"far " + (item.hasOnlineLearningTeacherRole ? "fa-check-square" : "fa-square")}></i></span>
                                },
                                {
                                    text: "Gennemførelse admin", width: "12%", sortProp: 'hasWrittenAttendanceAdminRole', cellRender: item =>
                                        <span className="px-3" onClick={() => this.toggleWrittenAttendanceAdminRole(item)} ><i className={"far " + (item.hasWrittenAttendanceAdminRole ? "fa-check-square" : "fa-square")}></i></span>
                                },
                                {
                                    text: "Styring og planlægning", width: "12%", sortProp: 'hasManagementAndPlanningRole', cellRender: item =>
                                        <span className="px-3" onClick={() => this.toggleManagementAndPlanningRole(item)}><i className={"far " + (item.hasManagementAndPlanningRole ? "fa-check-square" : "fa-square")}></i></span>
                                },
                                {
                                    text: "Observer", width: "12%", sortProp: 'hasObserverRole', cellRender: item =>
                                        <span className="px-3" onClick={() => this.toggleObserverRole(item)}><i className={"far " + (item.hasObserverRole ? "fa-check-square" : "fa-square")}></i></span>
                                },
                                {
                                    text: "Slet", width: "8%", cellRender: item =>
                                        <span onClick={() => this.removeAppUser(item)}><i className={"px-3 text-danger fa fa-times"}></i></span>
                                }
                            ]} items={this.state.appUsers} >
                            </ItemTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserRoles;
