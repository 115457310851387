import React from 'react';

function SortableHeader({ sortProp, sortDesc, propName, onClick, children }) {
    return (
        <div className="sort-header" style={{ display: 'flex' }} onClick={() => onClick(propName)}>
            <div>{children}</div>
            <div style={{ marginLeft: '16px' }}>
                {sortProp !== propName &&
                    <i className="fas fa-sort sort-icon"></i>
                }
                {sortProp === propName && sortDesc === false &&
                    <i className="fas fa-sort-up"></i>
                }
                {sortProp === propName && sortDesc === true &&
                    <i className="fas fa-sort-down"></i>
                }
            </div>
        </div>
    )
}

export default SortableHeader;
