import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import api from "../services/api";

const AccountContext = createContext();

function AccountProvider({ children }) {
    const [account, setAccount] = useState(null);
    const accountContextValue = useMemo(() => ({ account, setAccount }), [account]);

    return (
        <AccountContext.Provider value={accountContextValue}>
            {children}
        </AccountContext.Provider>
    )
}

function useAccount() {
    const { account, setAccount } = useContext(AccountContext);
    return { account, setAccount };
}

function Account() {
    const { account, setAccount } = useAccount();

    const accountRef = useRef(null);
    const [accountLoading, setAccountLoading] = useState(false);

    const [gsiClientInitialized, setGsiClientInitialized] = useState(false);
    const [gsiCredential, setGsiCredential] = useState(null);

    useEffect(() => {
        const initializeGsiClient = () => {
            window.google.accounts.id.initialize({
                client_id: process.env.REACT_APP_CLIENT_ID,
                auto_select: "true",
                callback: x => setGsiCredential(x.credential)
            });
            setGsiClientInitialized(true);
        }

        const exitingScript = document.getElementById("gsi-client");
        if (exitingScript) {
            initializeGsiClient();
            return;
        }

        const script = document.createElement("script");
        script.id = "gsi-client";
        script.async = true;
        script.src = "https://accounts.google.com/gsi/client";
        script.onload = initializeGsiClient;
        document.body.appendChild(script);
    }, []);

    useEffect(() => {
        setAccountLoading(true);
        api.onCredentialChange(gsiCredential, () => setGsiCredential(null)).then(x => {
            setAccountLoading(false);
            setAccount(x);
        }, () => {
            setAccountLoading(false);
        });
    }, [gsiCredential, setAccount]);

    const googleButtonRef = useCallback(node => {
        if (node === null || gsiClientInitialized === false) {
            return;
        }

        window.google.accounts.id.renderButton(
            node,
            {
                type: "standard",
                shape: "rectangular",
                theme: "outline",
                text: "signin_with",
                size: "large",
                logo_alignment: "left"
            }
        );
    }, [gsiClientInitialized]);

    const handleLogout = () => {
        setGsiCredential(null);
        window.google.accounts.id.disableAutoSelect();
    }

    return (
        <>
            <div style={{ display: 'flex' }}>
                <div ref={accountRef}>
                    {accountLoading === true &&
                        <div style={{ border: '1px solid #ddd', borderRadius: '4px', display: 'flex', padding: '8px 16px' }}>
                            <div style={{ marginRight: '16px' }}>Loading Account</div>
                            <div className="save-spinner"></div>
                        </div>
                    }
                    {accountLoading === false && account === null &&
                        <div ref={googleButtonRef}></div>
                    }
                    {accountLoading === false && account !== null &&
                        <NavDropdown title={account.name} id="profile-dropdown" style={{ margin: '-1px', border: '1px solid #ddd', borderRadius: '4px' }}>
                            <NavDropdown.Item href="#" onClick={handleLogout}>Logout</NavDropdown.Item>
                        </NavDropdown>
                    }
                </div>
            </div>
        </>
    )
}

export { AccountProvider, useAccount, Account };
export { AccountContext } //only for legacy class components;
