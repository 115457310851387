import React, { useEffect, useState } from "react";
import api from '../../services/api';


function Emails({ text, emails, onChange }) {
    const [email, setEmail] = useState('');

    const handleAdd = () => {
        if (!emails.some(x => x === email)) {
            onChange([...emails, email]);
        }
        setEmail('');
    }

    const handleRemove = (e) => {
        onChange(emails.filter(x => x !== e));
    }

    return (
        <div style={{ margin: '0px 32px' }} >
            <div style={{ marginBottom: '16px' }}>
                <label>{text}</label>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1, marginRight: '16px' }}>
                        <input type="text" className="form-control" placeholder="email address" value={email} onChange={x => setEmail(x.target.value)} ></input>
                    </div>
                    <div>
                        <button className={"btn btn-outline-primary"} type="button" onClick={handleAdd} >Add</button>
                    </div>
                </div>
            </div>
            <div>
                {emails.map((x, i) =>
                    <div key={i} style={{ display: 'flex', padding: '8px', borderBottom: '1px solid #eee' }}>
                        <div style={{ flex: 1 }}>{x}</div>
                        <div onClick={() => handleRemove(x)}><i className={"px-3 text-danger fa fa-times"}></i></div>
                    </div>
                )}
            </div>
        </div>
    );
}

function checkHasChanges(a1, a2) {
    if (a1.length !== a2.length) {
        return true;
    }
    return a1.some(x => !a2.includes(x));
}

function ArchiveEmails() {

    const [archiveEmails, setArchiveEmails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [refreshToggle, setRefreshToggle] = useState(false);
    const [onlineLearningEmails, setOnlineLearningEmails] = useState([]);
    const [writtenAttendanceEmails, setWrittenAttendanceEmails] = useState([]);

    useEffect(x => {
        setLoading(true);
        api.getArchiveEmails().then(x => {
            setLoading(false);
            setArchiveEmails(x.data);
            setOnlineLearningEmails(x.data.onlineLearningEmails);
            setWrittenAttendanceEmails(x.data.writtenAttendanceEmails);
        });
    }, [refreshToggle]);


    const handleSave = () => {
        setSaving(true);
        api.updateArchiveEmails({ onlineLearningEmails, writtenAttendanceEmails }).then(x => {
            setSaving(false);
            setRefreshToggle(x = !x);
        })
    }

    const hasChanges = archiveEmails !== null && (checkHasChanges(archiveEmails.onlineLearningEmails, onlineLearningEmails) || checkHasChanges(archiveEmails.writtenAttendanceEmails, writtenAttendanceEmails));


    return (
        <div className="card" style={{ flex: 1 }}>
            <div className="card-body" style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                    <button className={"btn " + (saving ? "btn-outline-secondary disabled" : "btn-outline-success")} type="button" disabled={!hasChanges || saving} onClick={handleSave} >
                        <div className="float-left">
                            {saving && <div className="save-spinner"></div>}
                            {!saving && <i className="fas fa-check"></i>}
                        </div>
                        <div className="button-text">Save</div>
                    </button>
                </div>
                <div style={{ flex: 1, display: 'flex', marginTop: '16px' }}>
                    <div style={{ flex: 1 }}>
                        <Emails text={'Online learning archive receiving emails'} emails={onlineLearningEmails} onChange={setOnlineLearningEmails} />
                    </div>
                    <div style={{ borderLeft: '1px solid #eee' }}></div>
                    <div style={{ flex: 1 }}>
                        <Emails text={'Written attendance archive receiving emails'} emails={writtenAttendanceEmails} onChange={setWrittenAttendanceEmails} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ArchiveEmails;
