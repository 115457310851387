import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import api from '../services/api';



function SubmissionReport() {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [reportUrl, setReportUrl] = useState(null);

    const handleGenerateReport = () => {
        setLoading(true);
        api.onlineLearningSubmissionReport({fromDate, toDate}).then(response => {
            setLoading(false);
            setReportUrl(response.data.url);

        }).catch(error => {
            setLoading(false);
            console.log(error);
        });
    }

    return (
        <div className="card my-4">
            <div className="card-body">
                <h5 className="card-title">E-learning udtræk</h5>
                <div className="row">
                    <div className="col-3">
                        <label>Første dato:</label>
                        <DatePicker className="form-control ml-2" dateFormat="dd/MM - yyyy" selected={fromDate} onChange={x => setFromDate(x)} />
                    </div>
                    <div className="col-3">
                        <label>Sidst dato:</label>
                        <DatePicker className="form-control ml-2" dateFormat="dd/MM - yyyy" selected={toDate} onChange={x => setToDate(x)} />
                    </div>
                    <div className="col-3">
                        <button className="btn btn-outline-secondary" type="button" onClick={handleGenerateReport}>
                            <div className="float-left">
                                {loading && <div className="save-spinner"></div>}
                                {!loading && <i className="far fa-file-excel"></i>}
                            </div>
                            <div className="button-text">Opret udtræk</div>
                        </button>
                    </div>
                    <div className="col-3">
                        {reportUrl &&
                            <a href={reportUrl} target="_blank" rel="noopener noreferrer">
                                <div className="btn btn-outline-secondary">
                                    <div className="float-left">
                                        <i className="fa fa-external-link-alt m-1"></i>
                                    </div>
                                    <div className="button-text">Open sheet</div>
                                </div>
                            </a>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function WarnedReport() {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [includeExpelled, setIncludeExpelled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reportUrl, setReportUrl] = useState(null);

    const handleGenerateReport = () => {
        setLoading(true);
        api.onlineLearningWarnedReport({ fromDate, toDate, includeExpelled }).then(response => {
            setLoading(false);
            setReportUrl(response.data.url);

        }).catch(error => {
            setLoading(false);
            console.log(error);
        });
    }

    return (
        <div className="card my-4">
            <div className="card-body">
                <h5 className="card-title">E-learning varslede udtræk</h5>
                <div className="row">
                    <div className="col-3">
                        <label>Første dato:</label>
                        <DatePicker className="form-control ml-2" dateFormat="dd/MM - yyyy" selected={fromDate} onChange={x => setFromDate(x)} />
                    </div>
                    <div className="col-3">
                        <label>Sidst dato:</label>
                        <DatePicker className="form-control ml-2" dateFormat="dd/MM - yyyy" selected={toDate} onChange={x => setToDate(x)} />
                    </div>
                    <div className="col-3">
                        <button className="btn btn-outline-secondary" type="button" onClick={handleGenerateReport}>
                            <div className="float-left">
                                {loading && <div className="save-spinner"></div>}
                                {!loading && <i className="far fa-file-excel"></i>}
                            </div>
                            <div className="button-text">Opret udtræk</div>
                        </button>
                    </div>
                    <div className="col-3">
                        {reportUrl &&
                            <a href={reportUrl} target="_blank" rel="noopener noreferrer">
                                <div className="btn btn-outline-secondary">
                                    <div className="float-left">
                                        <i className="fa fa-external-link-alt m-1"></i>
                                    </div>
                                    <div className="button-text">Open sheet</div>
                                </div>
                            </a>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-3" style={{ marginTop: '16px' }}>
                        <label>Omfatte udmeldt:</label>
                        <span className="px-3"><i onClick={() => setIncludeExpelled(x => !x)} className={"far " + (includeExpelled ? "fa-check-square" : "fa-square")}></i></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ExpelledReport() {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [reportUrl, setReportUrl] = useState(null);

    const handleGenerateReport = () => {
        setLoading(true);
        api.onlineLearningExpelledReport(fromDate, toDate).then(response => {
            setLoading(false);
            setReportUrl(response.data.url);

        }).catch(error => {
            setLoading(false);
            console.log(error);
        });
    }

    return (
        <div className="card my-4">
            <div className="card-body">
                <h5 className="card-title">E-learning udmeldt udtræk</h5>
                <div className="row">
                    <div className="col-3">
                        <label>Første dato:</label>
                        <DatePicker className="form-control ml-2" dateFormat="dd/MM - yyyy" selected={fromDate} onChange={x => setFromDate(x)} />
                    </div>
                    <div className="col-3">
                        <label>Sidst dato:</label>
                        <DatePicker className="form-control ml-2" dateFormat="dd/MM - yyyy" selected={toDate} onChange={x => setToDate(x)} />
                    </div>
                    <div className="col-3">
                        <button className="btn btn-outline-secondary" type="button" onClick={handleGenerateReport}>
                            <div className="float-left">
                                {loading && <div className="save-spinner"></div>}
                                {!loading && <i className="far fa-file-excel"></i>}
                            </div>
                            <div className="button-text">Opret udtræk</div>
                        </button>
                    </div>
                    <div className="col-3">
                        {reportUrl &&
                            <a href={reportUrl} target="_blank" rel="noopener noreferrer">
                                <div className="btn btn-outline-secondary">
                                    <div className="float-left">
                                        <i className="fa fa-external-link-alt m-1"></i>
                                    </div>
                                    <div className="button-text">Open sheet</div>
                                </div>
                            </a>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}


function ManagementAndPlanning() {
    return (
        <>
            <SubmissionReport />
            <WarnedReport />
            <ExpelledReport />
        </>
    );
}

export default ManagementAndPlanning;
