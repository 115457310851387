import React, { Component, createRef } from 'react';

import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import FroalaEditor from 'react-froala-wysiwyg';
import api from '../../services/api';

class EmailTemplate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            templates: [],
            activeTemplate: null,
            saving: false,
            editorRef: createRef({ editor: null })
        };
    }

    handleSubjectChange(event) {
        let template = this.state.activeTemplate;
        template.subject = event.target.value;
        this.setState({ templates: [...this.state.templates] });
    }

    handleContentChange(content) {
        let template = this.state.activeTemplate;
        template.content = content;
        this.setState({ templates: [...this.state.templates] });
    }

    saveEmailTemplate() {
        this.setState({ saving: true });

        api.updateEmailTemplates(this.state.templates).then(response => {
            this.setState({ saving: false });
        }).catch(error => {
            this.setState({ saving: false });
        });
    }

    insertPlaceholder(item) {
        if (this.insertPlaceholderFnc) {
            this.insertPlaceholderFnc(item)
        }
    }

    switchTemplate(template) {
        this.insertPlaceholderFnc = null;
        this.setState({ activeTemplate: template });
    }

    onSubjectFocus(e) {
        let target = e.target;
        this.insertPlaceholderFnc = function (item) {
            let template = this.state.activeTemplate;
            let insertIndex = target.selectionStart;
            let updatedInsertIndex = insertIndex + item.value.length;
            template.subject = template.subject.substring(0, insertIndex) + item.value + template.subject.substring(insertIndex);
            target.focus();
            this.setState({ templates: [...this.state.templates] }, () => {
                target.setSelectionRange(updatedInsertIndex, updatedInsertIndex);
            })
        }
    }

    onContentFocus(e) {
        this.insertPlaceholderFnc = function (item) {
            this.state.editorRef.current.editor.html.insert(item.value);
        }
    }

    componentDidMount() {
        api.getEmailTemplates().then(response => {
            this.setState({ templates: response.data, activeTemplate: response.data[0] });
        }).catch(error => {
            console.log(error);
        });
    };

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <div className="row mb-4">
                        <div className="col-2">
                            <button className={"btn " + (this.state.saving ? "btn-outline-secondary disabled" : "btn-outline-success")} type="button" disabled={this.state.saving} onClick={this.saveEmailTemplate.bind(this)} >
                                <div className="float-left">
                                    {this.state.saving && <div className="save-spinner"></div>}
                                    {!this.state.saving && <i className="fas fa-check"></i>}
                                </div>
                                <div className="button-text">Save</div>
                            </button>
                        </div>
                        <div className="col-8">
                            {this.state.activeTemplate &&
                                <ul className="nav nav-pills" id="pills-tab">
                                    {this.state.templates.map(template =>
                                        <li key={template.id} className="nav-item mx-1" role="presentation">
                                            <button className={"btn " + (template === this.state.activeTemplate ? "btn-secondary" : "btn-outline-secondary")} onClick={() => this.switchTemplate(template)} type="button">{template.name}</button>
                                        </li>
                                    )}
                                </ul>
                            }
                        </div>
                    </div>
                    {this.state.activeTemplate &&
                        <div className="row">
                            <div className="col-10">
                                <div>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="subjectSideText">Subject:</span>
                                        </div>
                                        <input type="text" className="form-control" id="emailTemplateSubject" aria-describedby="subjectSideText" value={this.state.activeTemplate.subject} onChange={this.handleSubjectChange.bind(this)} onFocus={this.onSubjectFocus.bind(this)}></input>
                                    </div>
                                    <FroalaEditor
                                        ref={this.state.editorRef}
                                        config={{ height: 450, key: process.env.REACT_APP_FROALA_KEY, events: { 'focus': this.onContentFocus.bind(this) } }}
                                        model={this.state.activeTemplate.content}
                                        onModelChange={(c) => this.handleContentChange(c)} />
                                </div>
                            </div>
                            <div className="col-2">
                                <div>
                                    <label htmlFor="tokenList">Placeholders</label>
                                    <div id="tokenList" className="btn-group-vertical btn-block" role="group">
                                        {this.state.activeTemplate.placeholders.map((item, index) => {
                                            return (
                                                <button type="button" className="btn btn-outline-info my-0" onClick={() => this.insertPlaceholder(item)} key={item.value}>{item.name}</button>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default EmailTemplate;
