import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import StudentView from './StudentView';
import CourseView from './CourseView';
import format from 'date-fns/format';
import ActionButton from './ActionButton';
import { useAccount } from '../Account';


function OnlineLearning() {

    const { account } = useAccount();
    const [view, setView] = useState('all')

    const [studentFilterActive, setStudentFilterActive] = useState(false);
    const [courses, setCourses] = useState([]);
    const [coursesLoading, setCoursesLoading] = useState(false);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [students, setStudents] = useState([]);
    const [studentsLoading, setStudentsLoading] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState([]);

    const [courseIds, setCourseIds] = useState([]);
    const [studentIds, setStudentIds] = useState([]);
    const [reload, setReload] = useState({});

    const [actionDates, setActionDates] = useState([])
    const [nextActionDate, setNextActionDate] = useState('');

    const [updateActionDateInProgress, setUpdateActionDateInProgress] = useState(false);
    const [remindInProgress, setRemindInProgress] = useState(false);
    const [warnInProgress, setWarnInProgress] = useState(false);
    const [expelInProgress, setExpelInProgress] = useState(false);
    const [unexpelInProgress, setUnexpelInProgress] = useState(false);
    const [expelNoEmailInProgress, setExpelNoEmailInProgress] = useState(false);

    useEffect(() => {
        api.getOnlineLearningActionDates().then(response => {
            const dates = [{ text: 'Ryd dato', value: '' }];
            for (let i = 0; i < response.data.length; i++) {
                const formatedDate = format(new Date(response.data[i]), 'dd/MM - yyyy');
                dates.push({ text: formatedDate, value: response.data[i] });
            }
            setActionDates(dates)
        }).catch(error => {
            console.log(error);
        });
    }, [])

    useEffect(() => {
        if (studentFilterActive) {
            setCourseIds([]);
        } else {
            setStudentIds([]);
        }
    }, [studentFilterActive, view])

    const getCourses = (query) => {
        setCoursesLoading(true);
        api.getOnlineLearningCourses(query)
            .then(response => {
                setCourses(response.data);
                setCoursesLoading(false);
            })
            .catch(error => {
                setCoursesLoading(false);
                console.log(error);
            });
    }

    const getStudents = (query) => {
        setStudentsLoading(true);
        api.getOnlineLearningStudents(query)
            .then(response => {
                setStudents(response.data)
                setStudentsLoading(false);
            })
            .catch(error => {
                setStudentsLoading(false);
                console.log(error);
            });
    }

    const selectCourse = (sel) => {
        if (sel.length === 1) {
            setCourseIds([sel[0].id]);
        } else {
            setCourseIds([]);
        }
        setSelectedCourses(sel);
    }

    const selectStudent = (sel) => {
        if (sel.length === 1) {
            setStudentIds([sel[0].id]);
        } else {
            setStudentIds([]);
        }
        setSelectedStudents(sel);
    }

    const handleStudentFilterChange = (x) => {
        setStudentFilterActive(true);
        setSelectedStudents([x]);
        setStudentIds([x.id]);
    }

    const handleCourseFilterChange = (x) => {
        setStudentFilterActive(false);
        setSelectedCourses([x]);
        setCourseIds([x.id]);
    }

    const updateNextActionDate = () => {
        const courseStudents = [];
        for (let i = 0; i < courseIds.length; i++) {
            for (let j = 0; j < studentIds.length; j++) {
                courseStudents.push({ courseId: courseIds[i], studentId: studentIds[j] });
            }
        }

        setUpdateActionDateInProgress(true);
        api.updateOnlineLearningNextActionDate({ courseStudents, nextActionDate }).then(response => {
            setUpdateActionDateInProgress(false);
            setReload({});
        }, e => {
            setUpdateActionDateInProgress(false);
        });
    }

    const remindStudent = () => {
        const courseStudents = [];
        for (let i = 0; i < courseIds.length; i++) {
            for (let j = 0; j < studentIds.length; j++) {
                courseStudents.push({ courseId: courseIds[i], studentId: studentIds[j] });
            }
        }

        setRemindInProgress(true);
        api.onlineLearningRemindStudent({ courseStudents }).then(response => {
            setRemindInProgress(false);
            setReload({});
        }, e => {
            setRemindInProgress(false);
        });
    }

    const warnStudent = () => {
        const courseStudents = [];
        for (let i = 0; i < courseIds.length; i++) {
            for (let j = 0; j < studentIds.length; j++) {
                courseStudents.push({ courseId: courseIds[i], studentId: studentIds[j] });
            }
        }

        setWarnInProgress(true);
        api.onlineLearningWarnStudent({ courseStudents }).then(response => {
            setWarnInProgress(false);
            setReload({});
        }, e => {
            setWarnInProgress(false);
        });
    }

    const expelStudent = () => {
        const courseStudents = [];
        for (let i = 0; i < courseIds.length; i++) {
            for (let j = 0; j < studentIds.length; j++) {
                courseStudents.push({ courseId: courseIds[i], studentId: studentIds[j] });
            }
        }

        setExpelInProgress(true);
        api.onlineLearningExpelStudent({ courseStudents }).then(response => {
            setExpelInProgress(false);
            setReload({});
        }, e => {
            setExpelInProgress(false);
        });
    }

    const expelStudentNoEmail = () => {
        const courseStudents = [];
        for (let i = 0; i < courseIds.length; i++) {
            for (let j = 0; j < studentIds.length; j++) {
                courseStudents.push({ courseId: courseIds[i], studentId: studentIds[j] });
            }
        }

        setExpelNoEmailInProgress(true);
        api.onlineLearningExpelStudentNoEmail({ courseStudents }).then(response => {
            setExpelNoEmailInProgress(false);
            setReload({});
        }, e => {
            setExpelNoEmailInProgress(false);
        });
    }

    const unexpelStudent = () => {
        const courseStudents = [];
        for (let i = 0; i < courseIds.length; i++) {
            for (let j = 0; j < studentIds.length; j++) {
                courseStudents.push({ courseId: courseIds[i], studentId: studentIds[j] });
            }
        }

        setUnexpelInProgress(true);
        api.onlineLearningUnexpelStudent({ courseStudents }).then(response => {
            setUnexpelInProgress(false);
            setReload({});
        }, e => {
            setUnexpelInProgress(false);
        });
    }

    const handleStudentFilterToggle = (active) => {
        if (active) {
            if (selectedStudents.length === 1) {
                setStudentIds([selectedStudents[0].id]);
            } else {
                setStudentIds([]);
            }
        } else {
            if (selectedCourses.length === 1) {
                setCourseIds([selectedCourses[0].id]);
            } else {
                setCourseIds([]);
            }
        }
        setStudentFilterActive(active);
    }

    const canExecute = account.isAdmin || account.isOnlineLearningAdmin;

    const buttonsDisabled = courseIds.length === 0 || studentIds.length === 0;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ display: 'flex', marginBottom: '16px' }}>
                <div style={{ flex: 1, maxWidth: 700 }}>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <button className={"btn " + (studentFilterActive === false ? "btn-secondary" : "btn-outline-secondary")} onClick={() => handleStudentFilterToggle(false)}>Hold</button>
                            <button className={"btn " + (studentFilterActive === true ? "btn-secondary" : "btn-outline-secondary")} onClick={() => handleStudentFilterToggle(true)}>Elev</button>
                        </div>
                        {studentFilterActive === false &&
                            <AsyncTypeahead
                                id="course-typeahead"
                                isLoading={coursesLoading}
                                labelKey="name"
                                onSearch={getCourses}
                                options={courses}
                                selected={selectedCourses}
                                onChange={selectCourse}
                                delay={300}
                                minLength={3}
                                clearButton
                                promptText=""
                            />
                        }
                        {studentFilterActive === true &&
                            <AsyncTypeahead
                                id="student-typeahead"
                                isLoading={studentsLoading}
                                labelKey={(x) => x.firstname + " " + x.lastname}
                                onSearch={getStudents}
                                options={students}
                                selected={selectedStudents}
                                onChange={selectStudent}
                                delay={300}
                                minLength={3}
                                clearButton
                                promptText=""
                            />
                        }
                    </div>
                </div>
                <div style={{ flex: 1, marginLeft: 64, maxWidth: 700 }}>
                    <button className={"mx-1 btn " + (view === "all" ? "btn-info" : "btn-outline-info")} onClick={() => setView("all")}>Alle</button>
                    <button className={"mx-1 btn " + (view === "behind" ? "btn-info" : "btn-outline-info")} onClick={() => setView("behind")}>Bagud</button>
                    <button className={"mx-1 btn " + (view === "warned" ? "btn-info" : "btn-outline-info")} onClick={() => setView("warned")}>Varslet</button>
                    <button className={"mx-1 btn " + (view === "expelled" ? "btn-info" : "btn-outline-info")} onClick={() => setView("expelled")}>Udmeldt</button>
                </div>
            </div>

            <div style={{ flex: 1 }}>
                {studentFilterActive === false &&
                    <CourseView courseIds={courseIds} studentIds={studentIds} onStudentIdsChange={setStudentIds} onStudentFilterChange={handleStudentFilterChange} canComment={canExecute} view={view} reload={reload} />
                }
                {studentFilterActive === true &&
                    <StudentView courseIds={courseIds} studentIds={studentIds} onCourseIdsChange={setCourseIds} onCourseFilterChange={handleCourseFilterChange} canComment={canExecute} view={view} reload={reload} />
                }
            </div>
            <div style={{ display: 'flex' }}>
                {canExecute &&
                    <>
                        {view !== "expelled" &&
                            <>
                                <div className="m-2" style={{ width: '200px' }}>
                                    <select className="form-control" value={nextActionDate} onChange={(x) => setNextActionDate(x.target.value)}>
                                        {actionDates.map((item) => {
                                            return (
                                                <option key={item.value} value={item.value}>{item.text}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="m-2">
                                    <ActionButton disabled={buttonsDisabled} text={"Næste varselsdato"} icon={<i className="fas fa-clock" />} onClick={() => updateNextActionDate()} inProgress={updateActionDateInProgress} />
                                </div>
                                <div className="m-2">
                                    <ActionButton disabled={buttonsDisabled} text={"Påmindelsesbrev"} icon={<i className="fas fa-envelope" />} onClick={() => remindStudent()} inProgress={remindInProgress} />
                                </div>
                                <div className="m-2">
                                    <ActionButton disabled={buttonsDisabled} text={"Tildel varsel"} icon={<i className="fas fa-exclamation-triangle" />} onClick={() => warnStudent()} inProgress={warnInProgress} />
                                </div>
                                <div className="m-2">
                                    <ActionButton disabled={buttonsDisabled} text={"Udmeld"} icon={<i className="fas fa-user-slash" />} onClick={() => expelStudent()} inProgress={expelInProgress} />
                                </div>
                                <div className="m-2">
                                    <ActionButton disabled={buttonsDisabled} text={"Udmeldt uden varsel"} icon={<i className="fas fa-user-slash" />} onClick={() => expelStudentNoEmail()} inProgress={expelNoEmailInProgress} />
                                </div>
                            </>
                        }
                        {view === "expelled" &&
                            <>
                                <div className="m-2">
                                    <ActionButton disabled={buttonsDisabled} text={"Fortryd udmelding"} icon={<i className="fas fa-undo" />} onClick={() => unexpelStudent()} inProgress={unexpelInProgress} />
                                </div>
                            </>
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default OnlineLearning;
