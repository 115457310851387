import React, { Component } from 'react';

class ItemTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            sortColumn: null,
            sortDesc: false,
        }
    };

    selectItem(item) {
        item.selected = !item.selected;
        this.setState({ items: [...this.state.items] });
    }

    selectAllItems() {
        let selected = !this.state.items.every(x => x.selected);
        this.state.items.forEach(s => s.selected = selected);
        this.setState({ items: [...this.state.items] });
    }

    componentDidMount() {
        this.setState({ items: this.props.items });
    };

    onSort(column) {
        if (!column.sortProp) {
            return;
        }
        /// check of equal against text because for some reason sortColumn === column returns false even when is the same column in some cases
        if (this.state.sortColumn && this.state.sortColumn.text === column.text) {
            this.setState({ sortDesc: !this.state.sortDesc, items: [...this.state.items.reverse()] });
        } else {
            this.setState({ sortColumn: column, sortDesc: false, items: this.sortItems(this.state.items, column.sortProp) });
        }
    }

    sortItems(items, prop) {
        return items.sort((a, b) => {
            if (typeof (a[prop]) === "number") {
                return a[prop] - b[prop];
            }
            if (typeof (a[prop]) === "boolean") {
                return (a[prop] === b[prop]) ? 0 : a[prop] ? -1 : 1;
            }
            return a[prop].localeCompare(b[prop]);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.items !== prevProps.items) {
            let items = this.props.items;
            if (this.state.sortColumn) {
                items = this.sortItems(items, this.state.sortColumn.sortProp);
                if (this.state.sortDesc) {
                    items.reverse();
                }
            }
            this.setState({ items: items })
        }
        if (this.state.items !== prevState.items) {
            if (this.props.onSelectItems) {
                this.props.onSelectItems(this.state.items.filter(x => x.selected));
            }
        }
    }

    render() {
        return (
            <>
                {this.props.message &&
                    <div className="card m-4">
                        <div className="card-body">
                            {this.props.message}
                        </div>
                    </div>
                }
                {!this.props.message &&
                    <table className="table table-hover table-sm table-on-hover" style={{ backgroundColor: 'white', border: '1px solid #e9ecef', tableLayout: 'fixed' }}>
                        <thead>
                            <tr>
                                {this.props.onSelectItems &&
                                    <th style={{ width: '32px' }} >
                                        <i onClick={() => this.selectAllItems()} className={"far " + (this.state.items.every(x => x.selected) ? "fa-check-square" : "fa-square")}></i>
                                    </th>
                                }
                                {this.props.columns.map((column, index) =>
                                    <th key={index} style={{ width: column.width }} className={column.sortProp ? "clickable" : ""} onClick={() => this.onSort(column)} title={column.text}>
                                         {column.sortProp &&
                                            <span>
                                                {(!this.state.sortColumn || this.state.sortColumn.text !== column.text) &&
                                                    <i className="fas fa-sort hoverable"></i>
                                                }
                                                {this.state.sortColumn && this.state.sortColumn.text === column.text && this.state.sortDesc === false &&
                                                    <i className="fas fa-sort-up"></i>
                                                }
                                                {this.state.sortColumn && this.state.sortColumn.text === column.text && this.state.sortDesc === true &&
                                                    <i className="fas fa-sort-down"></i>
                                                }
                                            </span>
                                        }
                                        {column.text}
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.items.map((item, index) =>
                                <tr key={item.id}>
                                    {this.props.onSelectItems &&
                                        <td>
                                            <i onClick={() => this.selectItem(item)} className={"far " + (item.selected ? "fa-check-square" : "fa-square")}></i>
                                        </td>
                                    }
                                    {this.props.columns.map((column, index) =>
                                        <td key={index} className="ellipsis">{column.cellRender(item)}</td>
                                    )}
                                </tr>
                            )}
                        </tbody>
                    </table>
                }
            </>
        )
    }
};

export default ItemTable;